import { Col, Row,Carousel, Image } from "react-bootstrap"
import BannerSider from '../images/banner-1.png';
import BannerSider2 from '../images/banner-2.png';
import BannerSider3 from '../images/banner-3.png';
export const BannerSlider = () => {
    return(
        <section className="banner-slider">
            <Row>
                <Col md="12" lg="12" sm="12" xs="12">
                    <Carousel>
                        <Carousel.Item>
                        <Image src={BannerSider} alt="BannerSider" title="BannerSider" className="w-100"  />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image src={BannerSider2} alt="BannerSider" title="BannerSider" className="w-100"  />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image src={BannerSider3} alt="BannerSider" title="BannerSider" className="w-100" />
                        </Carousel.Item>
                    </Carousel>
                </Col>
            </Row>
        </section>
    )
}
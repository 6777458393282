import { Col, Container, Row, Button } from "react-bootstrap"

export const SponsporshipTime=() => {
    return(
        <section className="sponsporship-time">
            <Container>
                <Row className="justify-content-center">
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="sponsporship-time-block">
                            <h4 className="sponsporship-title">Support a Vedic Student <br/>
                            For a Month - Food & Education</h4>
                            <Button className="btn btn-lg btn-primary mt-3">Sponsorship</Button>
                        </div>
                    </Col>
                    {/* <Col md="6" lg="6" sm="6" xs="12" className="border-left-white mobile-spac mobile-border-none">
                        <div className="sponsporship-time-block">
                            <h4 className="sponsporship-title">Every rupee makes a difference. <br/>
                            Click here to Donate as you wish</h4>
                            <Button className="btn btn-lg btn-primary mt-3">Donate Now</Button>
                        </div>
                    </Col> */}
                </Row>
            </Container>
        </section>
    )
}
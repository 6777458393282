import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PageBreadcrumb } from "../components/PageBreadcrumb"
import Gallery1 from '../images/Gallery/1.png';
import Gallery2 from '../images/Gallery/2.png';
import Gallery3 from '../images/Gallery/3.png';
import Gallery4 from '../images/Gallery/4.png';
import Gallery5 from '../images/Gallery/5.png';
import Gallery6 from '../images/Gallery/6.png';
import Gallery7 from '../images/Gallery/7.png';
import Gallery8 from '../images/Gallery/8.png';
import Gallery9 from '../images/Gallery/9.png';
import Gallery10 from '../images/Gallery/10.png';
import Gallery11 from '../images/Gallery/11.png';
import Gallery12 from '../images/Gallery/12.png';
import Gallery13 from '../images/Gallery/13.png';
import Gallery14 from '../images/Gallery/14.png';
import Gallery15 from '../images/Gallery/15.png';
import Gallery16 from '../images/Gallery/16.png';
import Gallery17 from '../images/Gallery/17.png';
import Gallery18 from '../images/Gallery/18.png';
import Gallery19 from '../images/Gallery/19.png';
import Gallery20 from '../images/Gallery/20.png';
import Gallery21 from '../images/Gallery/21.png';
import Gallery22 from '../images/Gallery/22.png';
import Gallery23 from '../images/Gallery/23.png';
import Gallery24 from '../images/Gallery/24.png';
import Gallery25 from '../images/Gallery/25.png';
import Gallery26 from '../images/Gallery/26.png';
import Gallery27 from '../images/Gallery/27.png';
import Gallery28 from '../images/Gallery/28.png';
import Gallery29 from '../images/Gallery/29.png';
import Gallery30 from '../images/Gallery/30.png';
import Gallery31 from '../images/Gallery/31.png';
import Gallery32 from '../images/Gallery/32.png';
import Gallery33 from '../images/Gallery/33.png';
import Gallery34 from '../images/Gallery/34.png';
import Gallery35 from '../images/Gallery/35.png';
import Gallery36 from '../images/Gallery/36.png';
import Gallery37 from '../images/Gallery/37.png';
import Gallery38 from '../images/Gallery/38.png';
import Gallery39 from '../images/Gallery/39.png';
import Gallery40 from '../images/Gallery/40.png';
import Gallery41 from '../images/Gallery/41.png';
import Gallery42 from '../images/Gallery/42.png';
import Gallery43 from '../images/Gallery/43.png';
import Gallery44 from '../images/Gallery/44.png';
import Gallery45 from '../images/Gallery/45.png';
import Gallery46 from '../images/Gallery/46.png';
import Gallery47 from '../images/Gallery/47.png';
import Gallery48 from '../images/Gallery/48.png';
import Gallery49 from '../images/Gallery/49.png';
import Gallery50 from '../images/Gallery/50.png';
import Gallery51 from '../images/Gallery/51.png';
import Gallery52 from '../images/Gallery/52.png';
import Gallery53 from '../images/Gallery/53.png';
import Gallery54 from '../images/Gallery/54.png';
import Gallery55 from '../images/Gallery/55.png';
import Gallery56 from '../images/Gallery/56.png';
import Gallery57 from '../images/Gallery/57.png';
import Gallery58 from '../images/Gallery/58.png';
import Gallery59 from '../images/Gallery/59.png';
import Gallery60 from '../images/Gallery/60.png';
import Gallery61 from '../images/Gallery/61.png';
import Gallery62 from '../images/Gallery/62.png';
import Gallery63 from '../images/Gallery/63.png';
import Gallery64 from '../images/Gallery/64.png';
import Gallery65 from '../images/Gallery/65.png';
import Gallery66 from '../images/Gallery/66.png';
import Gallery67 from '../images/Gallery/67.png';
import Gallery68 from '../images/Gallery/68.png';
import Gallery69 from '../images/Gallery/69.png';
import Gallery70 from '../images/Gallery/70.png';
import Gallery71 from '../images/Gallery/71.png';
import Gallery72 from '../images/Gallery/72.png';
import Gallery73 from '../images/Gallery/73.png';
import Gallery74 from '../images/Gallery/74.png';
import Gallery75 from '../images/Gallery/75.png';
import Gallery76 from '../images/Gallery/76.png';
import Gallery77 from '../images/Gallery/77.png';
import Gallery78 from '../images/Gallery/78.png';
import Gallery79 from '../images/Gallery/79.png';
import Gallery80 from '../images/Gallery/80.png';
import Gallery81 from '../images/Gallery/81.png';
import Gallery82 from '../images/Gallery/82.png';
import Gallery83 from '../images/Gallery/83.png';
import Gallery84 from '../images/Gallery/84.png';
import Gallery85 from '../images/Gallery/85.png';
import Gallery86 from '../images/Gallery/86.png';
import Gallery87 from '../images/Gallery/87.png';
import Gallery88 from '../images/Gallery/88.png';
import Gallery89 from '../images/Gallery/89.png';
import Gallery90 from '../images/Gallery/90.png';
import Gallery91 from '../images/Gallery/91.png';
import Gallery92 from '../images/Gallery/92.png';
import Gallery93 from '../images/Gallery/93.png';
import Gallery94 from '../images/Gallery/94.png';
import Gallery95 from '../images/Gallery/95.png';
import Gallery96 from '../images/Gallery/96.png';
import Gallery97 from '../images/Gallery/97.png';
import Gallery98 from '../images/Gallery/98.png';
import Gallery99 from '../images/Gallery/99.png';
import Gallery100 from '../images/Gallery/100.png';
import 'react-lazy-load-image-component/src/effects/blur.css';
export const Gallery = () => {
    return(
        <>
          <PageBreadcrumb title="Gallery" />
          <Container>
                <Row className="mt-5 gallery-page">
                <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                    <video width="100%" height="auto" controls>
                        <source src="./videos/video-1.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <video width="100%" height="100%" controls>
                            <source src="./videos/video-2.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery1}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery2}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery3}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery4}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery5}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery6}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery7}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery8}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery9}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery10}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery11}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery12}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery13}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery14}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery15}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery16}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery17}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery18}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery19}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery20}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery21}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery22}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery23}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery24}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery25}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery26}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery27}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery28}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery29}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery30}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery31}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery32}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery33}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery34}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery35}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery36}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery37}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery38}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery39}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery40}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery41}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery42}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery43}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery44}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery45}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery46}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery47}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery48}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery49}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery50}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery51}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery52}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery53}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery54}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery55}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery56}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery57}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery58}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery59}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery60}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery61}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery62}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery63}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery64}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery65}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery66}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery67}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery68}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery69}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery70}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery71}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery72}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery73}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery74}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery75}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery76}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery77}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery78}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery79}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery80}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery81}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery82}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery83}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery84}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery85}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery86}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery87}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery88}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery89}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery90}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery91}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery92}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery93}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery94}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery95}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery96}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery97}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery98}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery99}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mt-3">
                        <LazyLoadImage
                        alt="Gallery"
                        src={Gallery100}
                        effect="blur"
                        width="100%"
                     afterLoad={() => console.log('checked')}
                     beforeLoad={() => console.log('checked')}
                        />
                    </Col>
                    
                </Row>
          </Container>
        </>
    )
}
import { Container, Row, Col, Button, Image } from "react-bootstrap"
import CourseVedanta1 from '../images/Course1_Vedam-01.png';
import CourseVedanta2 from '../images/Course2_Vedam+JARA+GHANA-01.png';
export const OurCourses = () => {
    return(
        <section className="our-courses">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <h4 className="section-title text-center">Our Courses</h4>
                        <Row className="mt-5">
                            <Col md="7" lg="7" sm="7" xs="12" className="self-center">
                                <div className="courses-section margin-course-right">
                                    <Image src={CourseVedanta1} alt="CourseVedanta" title="CourseVedanata" className="w-100" />
                                    <h4 className="courses-caption">Vedam</h4>
                                    <Row className="mt-3">
                                        <Col md="6" lg="6" sm="6" xs="6">
                                            <h6 className="course-duration">Course Duration</h6>
                                            <h6 className="course-years">12 years</h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="6" xs="6" className="border-left-white">
                                            <h6 className="course-duration">Eligibility Age</h6>
                                            <h6 className="course-years">10 years</h6>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col md="5" lg="5" sm="5" xs="12" className="mobile-spac self-center">
                                <h4 className="courses-title mb-3">Course Details</h4>
                                <ul className="mt-3 mb-3">
                                    <li>Krishna Yajur Vedam + Kramantham</li>
                                    <li>Sagum Hitha</li>
                                    <li>Brahmana Bhagam + Aranyakam</li>
                                    <li>Padam</li>
                                    <li>Kramam</li>
                                    <li>Avarthi</li>
                                </ul>
                                <Button className="btn btn-secondary mt-3">Register Now</Button>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col md="5" lg="5" sm="5" xs="12" className="self-center">
                                <h4 className="courses-title mb-3">Course Details</h4>
                                <ul className="mt-3 mb-3">
                                    <li>Krishna Yajur Vedam + Kramantham</li>
                                    <li>Sagum Hitha</li>
                                    <li>Brahmana Bhagam + Aranyakam</li>
                                    <li>Padam</li>
                                    <li>Kramam</li>
                                    <li>Avarthi</li>
                                </ul>
                                <Button className="btn btn-secondary mt-3">Register Now</Button>
                            </Col>
                            <Col md="7" lg="7" sm="7" xs="12" className="mobile-spac self-center">
                                <div className="courses-section margin-course-left">
                                    <Image src={CourseVedanta2} alt="CourseVedanta" title="CourseVedanata" className="w-100" />
                                    <h4 className="courses-caption">VEDAM + JATA + GHANA</h4>
                                    <Row className="mt-3">
                                        <Col md="6" lg="6" sm="6" xs="6">
                                            <h6 className="course-duration">Eligibility Age</h6>
                                            <h6 className="course-years">14 to 20 years</h6>
                                        </Col>
                                        <Col md="6" lg="6" sm="6" xs="6" className="border-left-white">
                                            <h6 className="course-duration">Course Duration</h6>
                                            <h6 className="course-years">15 years</h6>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
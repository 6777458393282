import { Container, Row, Col, Image } from "react-bootstrap"
import Gallery1 from '../images/Gallery_Images-01.png';
import Gallery2 from '../images/Gallery_Images-02.png';
import Gallery3 from '../images/Gallery_Images-03.png';
import Gallery4 from '../images/Gallery_Images-04.png';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
export const StudentLife=() =>{
    const responsive = {
        0:{
                items:1,
                nav:true
            },
            600:{
                items:2,
                nav:false
            },
            1000:{
                items:4,
                nav:true,
                loop:false
            }
    }
    return(
        <section className="student-life">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <h4 className="section-title text-center"><span>Student Life</span></h4>
                        <p className="text-center">Our Veda Paatasala provide a holistic curriculum for all round development of
                        the students in today’s digital universe.</p>
                    </Col>
                </Row>
                <OwlCarousel className='owl-theme mt-5' loop margin={30}  responsive={responsive} dots={false}>
                    <div class='item'>
                        <div className="image-gallery">
                            <Image src={Gallery1} alt="Gallery1" title="Gallery1" className="w-100" />
                        </div>
                    </div>
                    <div class='item'>
                        <div className="image-gallery">
                            <Image src={Gallery2} alt="Gallery1" title="Gallery1" className="w-100" />
                        </div>
                    </div>
                    <div class='item'>
                        <div className="image-gallery">
                            <Image src={Gallery3} alt="Gallery1" title="Gallery1" className="w-100" />
                        </div>
                    </div>
                    <div class='item'>
                        <div className="image-gallery">
                            <Image src={Gallery4} alt="Gallery1" title="Gallery1" className="w-100" />
                        </div>
                    </div>
                </OwlCarousel>
            </Container>
        </section>
    )
}
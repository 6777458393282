import { Col, Container, Row,Image, Button } from "react-bootstrap"
import Logo from '../images/logo.png';
import LogoText from '../images/logo-text.png';
export const TopBar = () => {
    return(
        <section className="top-bar">
            <Container>
                <Row>
                    <Col md="8" lg="8" sm="8" xs="12">
                        <a className="logo" href={`${process.env.PUBLIC_URL}/`}>
                            <Image src={Logo} alt="Logo" title="Logo" className="logo-image" />
                            <Image src={LogoText} alt="LogoText" title="LogoText" className="logo-text" />
                        </a>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="text-right self-center mobile-spac">
                        <div className="buttons">
                            <Button className="btn btn-lg btn-primary">Sponsorship</Button>
                            <Button className="btn btn-lg btn-primary ml-sm">Donate Now</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
import { BannerSlider } from '../components/BannerSlider';
import { AboutUs } from '../components/AboutUs';
import { OurCourses } from '../components/OurCourses';
import { OurPrograms } from '../components/OurPrograms';
import { StudentLife } from '../components/StudentLife';
import { SponsporshipTime } from '../components/SponsporshipTime';
export const IndexPage = () => {
    return(
        <>
            <BannerSlider />
            <AboutUs />
            <OurCourses />
            <OurPrograms />
            <StudentLife />
            <SponsporshipTime />
        </>
    )
}
import { Col, Container, Row, Button } from "react-bootstrap"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
export const OurPrograms = () => {
    const responsive = {
        0:{
                items:1,
                nav:true
            },
            600:{
                items:2,
                nav:false
            },
            1000:{
                items:3,
                nav:true,
                loop:false
            }
    }
    return(
        <section className="programs">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <h4 className="section-title text-center"><span>Our Programs</span></h4>
                    </Col>
                </Row>
                <OwlCarousel className='owl-theme mt-5' loop margin={30}  responsive={responsive} dots={false}>
                    <div class='item'>
                        <div className="program-block">
                            <h4 className="program-title">GoPuja <br/><span>&nbsp;</span></h4>
                            <p>Lorem ipsum dolor sit amet,
                            consectetuer adipiscing elit,
                            sed </p>
                            <Button className="btn btn-secondary mt-3">Enroll Now</Button>
                        </div>
                    </div>
                    <div class='item'>
                        <div className="program-block">
                            <h4 className="program-title">Mahanyasa Purvaka <br/>Rudrabhishekam</h4>
                            <p>Lorem ipsum dolor sit amet,
                            consectetuer adipiscing elit,
                            sed </p>
                            <Button className="btn btn-secondary mt-3">Enroll Now</Button>
                        </div>
                    </div>
                    <div class='item'>
                        <div className="program-block">
                            <h4 className="program-title">Lalitha Sahasraname <br/>Kumkumarchana</h4>
                            <p>Lorem ipsum dolor sit amet,
                            consectetuer adipiscing elit,
                            sed </p>
                            <Button className="btn btn-secondary mt-3">Enroll Now</Button>
                        </div>
                    </div>
                    <div class='item'>
                        <div className="program-block">
                            <h4 className="program-title">GoPuja <br/><span>&nbsp;</span></h4>
                            <p>Lorem ipsum dolor sit amet,
                            consectetuer adipiscing elit,
                            sed </p>
                            <Button className="btn btn-secondary mt-3">Enroll Now</Button>
                        </div>
                    </div>
                </OwlCarousel>
            </Container>
        </section>
    )
}
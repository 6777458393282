import { Col, Container, Row } from "react-bootstrap"

export const Footer = () => {
    return(
        <section className="footer-block">
            <Container>
                <Row className="mb-5">
                    <Col md="7" lg="7" sm="7" xs="12">
                        <div className="footer-block-content">
                            <h4 className="section-title">Contact Us</h4>
                            <p className="mt-3">Sri Bhramari Surya Prakashaka Vijaya  Shankara  Veda  Smartha  Pathashala  - Trust , <br/>
                            Regd No : 143/Book-4/2006, <br/>
                            H.No: 5-431/2/8,
                            Jonnabanda, Brundavan Colony, Old Alwal,<br/>
                             Secunderabad - 500010 - Telangana India</p>
                            <p>Mobile Number: <a href="tel:+919441068285" target="_blank"  rel="noreferrer">+91 94410 68285</a>, <a href="tel:+918374716548" target="_blank"  rel="noreferrer">+91 8374 7165 48</a></p>
                            <p>Email: <a href="mailto:sribhramarivedapathasala@gmail.com" target="_blank"  rel="noreferrer">sribhramarivedapathasala@gmail.com</a></p>
                        </div>
                    </Col>
                    <Col md="5" lg="5" sm="5" xs="12" className="mobile-spac">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15219.539732683384!2d78.49129626288784!3d17.51300544751901!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1707566143887!5m2!1sen!2sin" width="100%" height="300" title="Map" allowfullscreen="" loading="lazy"></iframe>
                    </Col>
                </Row>
                <section className="copyright">
                    <p className="copyright-title text-center">Copyright © 2022 Bhramari Veda Patashala. All Rights Reserved</p>
                </section>
            </Container>
        </section>
    )
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { TopBar } from './components/TopBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/App.scss';
import { NavbarMenu } from './components/NavbarMenu';

import { Footer } from './components/Footer';
import { IndexPage } from './pages';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Gallery } from './pages/Gallery';
import { About } from './pages/about';
ReactDOM.render(
  <React.StrictMode>
    <TopBar />
    <NavbarMenu />
      <HashRouter basename={process.env.PUBLIC_URL} hashType='slash'>
        <Switch>
          <Route exact path="/" component={IndexPage} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/about" component={About} />
        </Switch>
      </HashRouter>
    <Footer />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Button, Col, Container, Row } from "react-bootstrap"

export const AboutUs = () => {
    return(
        <section className="about-us">
            <Container>
                <Row>
                    <Col md="8" lg="8" sm="8" xs="12">
                        <h4 className="section-title mb-3">About <span>Paatasala Trust</span></h4>
                        <p><strong>Sri Bhramari Surya Prakashaka Vijaya Shankara Veda Smartha Patashala Trust</strong> is a trust registered in Telangana on 2006 with its primary operations being held in Alwal Paathashala.</p>
                        <p>Our Veda Patashala currently focuses on two important courses which is <strong>VEDAM</strong> and <strong>VEDAM + JATA + GHANA</strong> and are slowly expanding to other courses.  Over the last few years of establishment we have successfully churned out _ of Veda Pandits and __ of students are working in a various capacities.</p>
                        <p>Our activities extend beyond teaching following the highest traditions of Guru Parampara and Sanatana Dharma in our school.</p>
                        <Button className="btn btn-secondary">Know More</Button>
                    </Col>
                    <Col md="4" lg="4" sm="4" xs="12" className="mobile-spac">
                        <div className="about-block-content">
                            <h4>Sri Sringeri <br/>
                            Sharada Peetham</h4>
                        </div>
                        <div className="about-block-content mt-4">
                            <h4>Chityala <br/>Venugopalaswami Temple</h4>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
import { Col, Container, Row } from "react-bootstrap"

export const PageBreadcrumb = (props) => {
    return(
        <section className="page-breadcrumb">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <h4 className="section-title">{props.title}</h4>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
import { Image, Container, Row, Col, Card } from "react-bootstrap"
import AboutUsImage from '../images/aboutus-banner.png'
import NewBuildingInnagurationdisplayboard from '../images/NewBuilding-Innaguration-display-board.png';
import Members from '../images/default_profile_pic.jpg';
import { SponsporshipTime } from "../components/SponsporshipTime";
export const About = () => {
    return(
        <>
            <section className="about-us-page-banner">
                <Image src={AboutUsImage} alt="about-us-image" title="about-us-image" className="w-100" />
            </section>
            <section className="our-courses">
                <Container>
                    <h4 className="section-title mb-3 text-center">About <span>Paatasala Trust</span></h4>
                    <Row className="mt-5">
                        <Col md="8" lg="8" sm="8" xs="12">
                            <p><strong>Sri Bhramari Surya Prakashaka Vijaya Shankara Veda Smartha Patashala Trust</strong> is a trust registered in Telangana on 2006 with its primary operations being held in Alwal Paathashala.</p>
                            <p>Our Veda Patashala currently focuses on two important courses which is <strong>VEDAM</strong> and <strong>VEDAM + JATA + GHANA</strong> and are slowly expanding to other courses.  Over the last few years of establishment we have successfully churned out _ of Veda Pandits and __ of students are working in a various capacities.</p>
                            <p>Our activities extend beyond teaching following the highest traditions of Guru Parampara and Sanatana Dharma in our school.</p>
                        </Col>
                        <Col md="4" lg="4" sm="4" xs="12" className="mobile-spac">
                            <Image src={NewBuildingInnagurationdisplayboard} alt="NewBuilding-Innaguration-display-board" title="NewBuilding-Innaguration-display-board" className="w-100" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="members-boards">
                <Container>
                    <h4 className="section-title mb-3 text-center">Members: <span>Board of Trustees</span></h4>
                    <Row className="mt-3 justify-content-center">
                        <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                            <Card>
                                <Card.Body>
                                    <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                    <h4 className="member-title">Chebiyaam <br/>Raghava Sharma</h4>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                            <Card>
                                <Card.Body>
                                    <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                    <h4 className="member-title">Chebiyaam <br/> Sravan Kumar Sharma</h4>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                            <Card>
                                <Card.Body>
                                    <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                    <h4 className="member-title">Kashavojjala <br/> Shivaram Sharma</h4>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                            <Card>
                                <Card.Body>
                                    <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                    <h4 className="member-title">Chitti <br/>Sybramanyeswara Sharms</h4>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                            <Card>
                                <Card.Body>
                                    <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                    <h4 className="member-title">Smt. Chebiyyam <br/>Srilekha</h4>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <div className="mt-5">
                        <h4 className="section-title mb-3 text-center">Advisors to the Trustees</h4>
                        <Row className="mt-3">
                            <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                                <Card>
                                    <Card.Body>
                                        <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                        <h4 className="member-title">Vishubatla Subramanya Salakshana Ganapati Maha Maho Upadhya</h4>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                                <Card>
                                    <Card.Body>
                                        <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                        <h4 className="member-title">Guda Srinivasa Sharma Avadhani</h4>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                                <Card>
                                    <Card.Body>
                                        <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                        <h4 className="member-title">Chebiyyam Jayaorajasha Sharma</h4>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                                <Card>
                                    <Card.Body>
                                        <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                        <h4 className="member-title">Chebiyyam Jyothish Chandra</h4>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                                <Card>
                                    <Card.Body>
                                        <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                        <h4 className="member-title">Kuppu Kumara Swamy Sharma</h4>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                                <Card>
                                    <Card.Body>
                                        <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                        <h4 className="member-title">Chitti Srinivasa Sharma</h4>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                                <Card>
                                    <Card.Body>
                                        <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                        <h4 className="member-title">Kalavakolanu Sreramachadra Murthy Sharma</h4>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                                <Card>
                                    <Card.Body>
                                        <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                        <h4 className="member-title">Ganti Ramachandran</h4>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                                <Card>
                                    <Card.Body>
                                        <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                        <h4 className="member-title">Bamidipati Ramakrishna Sharma</h4>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                                <Card>
                                    <Card.Body>
                                        <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                        <h4 className="member-title">Gundlapally Narasimha Rao <br/>Advocate</h4>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                                <Card>
                                    <Card.Body>
                                        <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                        <h4 className="member-title">Lion Sanjeevi Satyanarayana</h4>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="2" lg="2" sm="2" xs="12" className="mt-3">
                                <Card>
                                    <Card.Body>
                                        <Image src={Members} alt="Board of Trustees" title="Board of Trustees" className="w-100" />
                                        <h4 className="member-title">Govindarajan Jayaraman</h4>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <SponsporshipTime />
        </>
    )
}